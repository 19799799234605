<template>
  <base-section
    id="partnerschulen"
    class="secondary"
  >
    <base-section-heading
      class="primary--text"
      title="Partnerschulen"
    />
    <v-container class="pr-16 pl-16">
      <p

        class="text-h5 accent--text font-weight-bold mr-6 ml-6 mb-4 text-center"
      >
        Unsere Partner-schulen in Dübendorf und Baden:
      </p>
    </v-container>
    <div
      class="py-14"
    />
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-avatar
            size="190"
          >
            <img
              :src="require('@/assets/DPC_ACADEMY_WHITE.png')"
              alt="DPC"
            >
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div class="ml-6 mr-8">
            <h3 class="text-uppercase text-h5 primary--text">
              DPC ACADEMY
            </h3>
            <div
              class="py-2"
            />
            <p
              class="text-h6 mr-12 accent--text text-none text-left"
            >
              DPC Academy<br>
              c/o Fabrik11<br>
              Ringstrasse 20<br>
              8600 Dübendorf<br>
            </p>
            <div
              class="py-2"
            />
            <div class="text-h6 mr-12 primary--text text-none text-left">
              <a href="https://www.dpcacademy.ch/?gclid=Cj0KCQjw5oiMBhDtARIsAJi0qk1_VIoq7ok-9zxuZ0O-DeEUHwoQoutIpi2jqZlpZOAQLALX57cjpdIaAg3xEALw_wcB">Webpage</a>
              <br>
            </div>
          </div>
          <div
            class="py-3"
          />
          <v-divider
            horizontal
            class="divider mx-6"
            width="400"
          />
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-center"
        />
      </v-row>
      <div
        class="py-14"
      />
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-avatar
            size="190"
          >
            <img
              :src="require('@/assets/DSB_logo.png')"
              alt="John"
            >
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div class="pl-6 mr-8">
            <h3 class="text-uppercase text-h5 primary--text">
              DSB
            </h3>
            <div
              class="py-2"
            />
            <p
              class="text-h6 mr-12 accent--text text-none text-left"
            >
              DANCE SCHOOL BADEN<br>
              Mellingerstrasse 6<br>
              5400 Baden
            </p>
            <div
              class="py-2"
            />
            <div class="text-h6 mr-12 primary--text text-none text-left">
              <a href="">Webpage</a>
              <br>
            </div>
          </div>
          <div
            class="py-3"
          />
          <v-divider
            horizontal
            class="divider mx-6"
            width="400"
          />
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-center"
        />
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
    }),
  }
</script>

<style>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent!important;
}

.list-header{
  font-size: 1.3rem!important;
  font-weight: 700;
}

.list-content{
  font-size: 1rem!important;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform:none!important;
}

.divider{
  border-color: rgba(240,222,222, 0.52)!important;
  border-width: 2px 0 0 0;
  margin-bottom:4px;
}
.v-application a{
  color: #FF2E2E!important;
}

</style>
